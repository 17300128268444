.login-page {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f5f5f5;
  padding: 20px;
}

.login-box {
  background: white;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
}

.login-header {
  text-align: center;
  margin-bottom: 30px;
}

.login-header h2 {
  color: #333;
  margin-bottom: 10px;
  font-size: 24px;
}

.login-header p {
  color: #666;
  margin: 0;
}

.login-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.login-button {
  cursor: pointer !important;
  padding: 12px 24px !important;
  background-color: #007bff !important;
  color: white !important;
  border: none !important;
  border-radius: 4px !important;
  font-size: 16px !important;
  width: 100% !important;
  transition: background-color 0.3s ease !important;
}

.login-button:hover {
  background-color: #0056b3 !important;
}

.login-button:disabled {
  opacity: 0.7;
  cursor: not-allowed !important;
}

.login-error {
  background-color: #fff2f2;
  border: 1px solid #ffcdd2;
  color: #d32f2f;
  padding: 12px;
  border-radius: 5px;
  text-align: center;
}
